import React, { useEffect, useState, useMemo, useContext } from 'react'
import * as classes from './Management.module.scss'
import Container from '../../../ui/Container/Container'
import SectionTitle from '../../../ui/SectionTitle/SectionTitle'
import { deleteHtmlTag } from '../../../../utils/deleteHtmlTag'
import { FormContext } from '../../../../context'
import splitRow from '../../../../utils/splitRow'
import { startAnimationScroll } from '../../../../utils/animations'
import splitTitleV2 from '../../../../utils/splitTitleV2'
import SliderCircleV2 from './SliderCircleV2/SliderCircleV2'

function Management({ data, lang }) {
    const { animationStart } = useContext(FormContext)

    const sectionTitle = useMemo(() => deleteHtmlTag(data.titleSmall.value, 'p'), [])
    const titleFormat = useMemo(() => deleteHtmlTag(data.title.value, 'p'), [])
    const text = useMemo(() => deleteHtmlTag(data.text.value, 'p'), [])
    const experienceTitle = useMemo(() => deleteHtmlTag(data.experienceTitle.value, 'p'), [])

    const content = useMemo(
        () => [
            {
                name: deleteHtmlTag(data.blockLeft.value, 'p'),
                photo: data.blockLeft.photo.url
            },
            {
                name: deleteHtmlTag(data.blockRight.value, 'p'),
                photo: data.blockRight.photo.url
            }
        ],
        []
    )

    const [title, setTitle] = useState({
        top: titleFormat,
        people: content && content.length ? content.map((item) => item.name) : [],
        experience: experienceTitle
    })

    const classAnimationTitle = 'anime-title-shareholders'
    const classAnimationText = 'anime-text-shareholders'

    useEffect(() => {
        if (animationStart) {
            splitRow(classAnimationText)
            splitTitleV2(classAnimationTitle)
        }

        const observer = startAnimationScroll([
            { type: 'title', className: classAnimationTitle },
            { type: 'text', className: classAnimationText }
        ])

        return () => {
            if (observer) {
                observer.disconnect()
            }
        }
    }, [animationStart])

    return (
        <section className={classes.Section}>
            {sectionTitle || title.top || text || (content && content.length) ? (
                <Container>
                    {sectionTitle ? <SectionTitle title={sectionTitle} lang={lang} /> : null}

                    {title || text ? (
                        <div className={classes.Content}>
                            {title.top ? <h2 className={classAnimationText} dangerouslySetInnerHTML={{ __html: title.top }} /> : null}

                            {text ? (
                                <div className={classAnimationText}>
                                    <p dangerouslySetInnerHTML={{ __html: text }} />
                                </div>
                            ) : null}
                        </div>
                    ) : null}

                    {content && content.length ? (
                        <div className={classes.Items}>
                            {content.map((item, index) => (
                                <div className={classes.Item} key={index}>
                                    {item.name ? (
                                        <p
                                            className={`${classes.ItemTitle} ${classAnimationTitle}`}
                                            dangerouslySetInnerHTML={{ __html: title.people[index] }}
                                        />
                                    ) : null}

                                    {item.photo ? (
                                        <div className={classes.ItemImg} style={{ backgroundImage: `url(${item.photo})` }} />
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    ) : null}
                </Container>
            ) : null}

            {title?.experience || data?.slides?.nodes?.length > 0 ? (
                <SliderCircleV2 title={title?.experience} data={data} classAnimationTitle={classAnimationTitle} />
            ) : null}
        </section>
    )
}

export default Management
