import React from 'react'
import * as classes from './SliderCircleV2.module.scss'
import cn from 'classnames'
import { useSlider } from './useSlider'
import IconRight from '../../../../icons/IconRight'

function SliderCircleV2({ data, title, classAnimationTitle }) {
    const { textsRef, logosRef, activeDotRef, slides, step, logoUrl, onChangeActiveSlide } = useSlider(data)

    const onMouseEnterHandler = (index) => {
        if (window.innerWidth <= 1280) {
            return
        }

        onChangeActiveSlide(index)
    }

    return (
        <div className={cn(classes.Experience, 'js-block-dark')}>
            {title && <p className={`${classes.ExperienceTitle} ${classAnimationTitle}`} dangerouslySetInnerHTML={{ __html: title }} />}

            {slides?.length > 0 && (
                <div className={classes.CircleWrap}>
                    <div className={`${classes.DotWrap} ${classes.Active}`} ref={activeDotRef}>
                        <span className={classes.Dot} />
                    </div>

                    {slides.map((item, index) => (
                        <div
                            className={cn(classes.DotWrap)}
                            key={item.id}
                            data-id={item.id}
                            style={{ transform: `rotate(${index * step - 0.7}deg)` }}
                            onClick={() => onChangeActiveSlide(index)}
                            onMouseEnter={() => onMouseEnterHandler(index)}
                        >
                            <span className={classes.Dot} />
                        </div>
                    ))}

                    <div className={classes.CircleContent}>
                        {logoUrl && (
                            <a className={classes.CircleBtn} href={`${logoUrl}`} target='_blank' rel='noopener noreferrer'>
                                <div className={classes.IconRightWrap}>
                                    <IconRight />
                                </div>
                            </a>
                        )}

                        {logoUrl && (
                            <div className={classes.LinkLearnMoreWrap + ' js-sliderCircleV2-linkLearnMoreWrap '}>
                                <a className={classes.LinkLearnMore} href={`${logoUrl}`} target='_blank' rel='noopener noreferrer'>
                                    Learn more
                                    <IconRight />
                                </a>
                            </div>
                        )}

                        <div className={classes.LogoWrap}>
                            {slides.map(
                                (item, index) =>
                                    item?.img && (
                                        <div
                                            className={classes.Logo}
                                            style={{ backgroundImage: `url(${item.img})` }}
                                            key={item.id}
                                            ref={(el) => (logosRef.current[index] = el)}
                                        />
                                    )
                            )}
                        </div>

                        <div className={classes.CircleTextWrap}>
                            {slides.map((item, index) => (
                                <p
                                    className={classes.CircleText}
                                    key={item.id}
                                    ref={(el) => (textsRef.current[index] = el)}
                                    dangerouslySetInnerHTML={{ __html: item.title }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SliderCircleV2
