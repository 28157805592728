import React, { useEffect, useState, useRef, useMemo, useLayoutEffect } from 'react'
import { deleteHtmlTag } from '../../../../../utils/deleteHtmlTag'
import anime from 'animejs'
import { useWindowSize } from '../../../../../hooks/useWindowSize'

export const useSlider = (data) => {
    const { width } = useWindowSize()

    const [activeSlide, setActiveSlide] = useState(0)

    const textsRef = useRef([])
    const logosRef = useRef([])
    const countRef = useRef(0)
    const activeDotRef = useRef(null)

    const slides = useMemo(
        () =>
            data.slides.nodes.map((item, index) => {
                return { title: deleteHtmlTag(item.name, 'p'), id: index, img: item?.logo?.url }
            }),
        []
    )

    const step = 360 / slides.length

    const logoUrl = data?.slides?.nodes?.[activeSlide]?.link || data?.slides?.nodes?.[activeSlide]?.linkOnFile?.url

    const onChangeActiveSlide = (customNewIndex) => {
        const offset = window.innerWidth > 767 ? 1.7 : 3

        let newIndex = activeSlide === slides.length - 1 ? 0 : activeSlide + 1

        if (customNewIndex > 0) {
            newIndex = customNewIndex
        }

        if (customNewIndex === 0) {
            newIndex = 0
        }

        if (+countRef.current >= 360 - offset) {
            countRef.current = 0
        }

        let startActiveDot = countRef.current

        let endActiveDot = `${+countRef.current > 0 ? +countRef.current + step : +countRef.current + step - offset}`

        if (customNewIndex) {
            endActiveDot = customNewIndex * step - offset
        }

        if (customNewIndex === 0) {
            endActiveDot = `${slides.length * step - offset}`
        }

        if (newIndex === 0 && activeSlide < Math.round(slides.length / 2)) {
            endActiveDot = -offset
        }

        if (activeSlide === 0 && newIndex === 10) {
            startActiveDot = -offset
            endActiveDot = startActiveDot - step
        }

        const linkMore = document.querySelector('.js-sliderCircleV2-linkLearnMoreWrap')

        anime({
            targets: activeDotRef.current,
            duration: 600,
            easing: 'cubicBezier(.66, 0, .3, .99)',
            rotate: [startActiveDot >= 360 - offset ? `-${offset}deg` : startActiveDot, `${endActiveDot}deg`],
            complete: () => {
                // подмена координаты, чтобы следующие слайды брали верные стартовые координаты
                if (activeSlide === 0 && newIndex === 10) {
                    countRef.current = (slides.length - 1) * step - offset
                }
            }
        })

        if (width <= 1280) {
            anime({
                targets: linkMore,
                duration: 300,
                opacity: [1, 0],
                easing: 'easeInOutQuad',
                complete: () => {
                    anime({
                        targets: linkMore,
                        duration: 300,
                        opacity: [0, 1],
                        easing: 'easeInOutQuad'
                    })
                }
            })
        }

        anime({
            targets: textsRef.current[activeSlide],
            duration: 600,
            opacity: [1, 0],
            easing: 'easeInOutQuad',
            top: [`${0}%`, `${-100}%`]
        })

        anime({
            targets: textsRef.current[newIndex],
            duration: 600,
            opacity: [0, 1],
            easing: 'easeInOutQuad',
            top: [`${100}%`, `${0}%`]
        })

        anime({
            targets: logosRef.current[activeSlide],
            duration: 600,
            opacity: [1, 0],
            easing: 'easeInOutQuad',
            top: [`${0}%`, `${-100}%`]
        })

        anime({
            targets: logosRef.current[newIndex],
            duration: 600,
            opacity: [0, 1],
            easing: 'easeInOutQuad',
            top: [`${100}%`, `${0}%`]
        })

        countRef.current = endActiveDot
        setActiveSlide(newIndex)
    }

    useLayoutEffect(() => {
        const offset = window.innerWidth > 767 ? 1.7 : 3
        activeDotRef.current.style.transform = `rotate(-${offset}deg)`
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
            onChangeActiveSlide()
        }, 5000)

        return () => clearInterval(intervalId)
    }, [activeSlide])

    useEffect(() => {
        textsRef.current = textsRef.current.slice(0, slides.length)
        logosRef.current = logosRef.current.slice(0, slides.length)
        textsRef.current[activeSlide].style.top = 0
        logosRef.current[activeSlide].style.top = 0
    }, [])

    return { textsRef, logosRef, activeDotRef, slides, step, logoUrl, onChangeActiveSlide }
}
