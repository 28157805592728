// extracted by mini-css-extract-plugin
export var Experience = "SliderCircleV2-module--Experience--DSca6";
export var ExperienceTitle = "SliderCircleV2-module--ExperienceTitle--KH6oY";
export var CircleWrap = "SliderCircleV2-module--CircleWrap--sfneL";
export var DotWrap = "SliderCircleV2-module--DotWrap--REHlQ";
export var Active = "SliderCircleV2-module--Active--zpSCG";
export var Dot = "SliderCircleV2-module--Dot--rYQzj";
export var CircleContent = "SliderCircleV2-module--CircleContent--X81lm";
export var CircleBtn = "SliderCircleV2-module--CircleBtn--vv4qf";
export var CircleTextWrap = "SliderCircleV2-module--CircleTextWrap--H5I6J";
export var CircleText = "SliderCircleV2-module--CircleText--F7Axo";
export var LogoWrap = "SliderCircleV2-module--LogoWrap--RNe+l";
export var Logo = "SliderCircleV2-module--Logo--9HDk0";
export var IconRightWrap = "SliderCircleV2-module--IconRightWrap--HBMKE";
export var LinkLearnMoreWrap = "SliderCircleV2-module--LinkLearnMoreWrap--1n0xj";
export var LinkLearnMore = "SliderCircleV2-module--LinkLearnMore--0+iYa";
export var arrowLeft = "SliderCircleV2-module--arrowLeft--jijSa";
export var arrowRight = "SliderCircleV2-module--arrowRight--etNWi";